import { createIcon } from "@chakra-ui/icons";

export const DeleteIcon = ({ fillColor }: { fillColor: string }) =>
  createIcon({
    displayName: "DeleteIcon",
    viewBox: "0 0 12 14",
    path: (
      <path
        d="M2.23881 13.52C1.86567 13.52 1.54866 13.3893 1.28776 13.1278C1.02627 12.867 0.895522 12.55 0.895522 12.177V1.25351H0V0.626755H3.31343V0H8.68657V0.626755H12V1.25351H11.1045V12.177C11.1045 12.5649 10.9776 12.8858 10.7239 13.1395C10.4701 13.3932 10.1493 13.52 9.76119 13.52H2.23881ZM10.4776 1.25351H1.52239V12.177C1.52239 12.3859 1.58955 12.5575 1.72388 12.6918C1.85821 12.8261 2.02985 12.8932 2.23881 12.8932H9.76119C9.9403 12.8932 10.1045 12.8186 10.2537 12.6694C10.403 12.5202 10.4776 12.356 10.4776 12.177V1.25351ZM4.25373 11.1025H4.8806V3.04424H4.25373V11.1025ZM7.1194 11.1025H7.74627V3.04424H7.1194V11.1025ZM1.52239 1.25351V12.8932V12.177V1.25351Z"
        fill={fillColor}
      />
    ),
  });
