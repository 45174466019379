import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import ContentLoader from "react-content-loader";
import { rem } from "polished";
import { media } from "@utils/media";

const QuestionLoader: FC = () => {
  return (
    <Flex width="100%" justifyContent="center">
      <Flex
        width="100%"
        gap={8}
        mt={16}
        mb={16}
        maxWidth={rem(544)}
        justifyContent="center"
        sx={{
          flexDirection: "row",
          [media.large]: {
            flexDirection: "column",
          },
        }}
      >
        <ContentLoader
          speed={2}
          width="100%"
          height="100%"
          viewBox="0 0 400 260"
          backgroundColor="#f3f3f3"
          foregroundColor="#e0e0e0"
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="36" />
          <rect x="0" y="42" rx="5" ry="5" width="100%" height="16" />
          <rect x="0" y="66" rx="5" ry="5" width="100%" height="16" />
          <rect x="0" y="110" rx="5" ry="5" width="90%" height="20" />
          <circle cx="390" cy="120" r="10" />
          <rect x="0" y="140" rx="5" ry="5" width="100%" height="2" />
          <rect x="0" y="152" rx="5" ry="5" width="90%" height="20" />
          <circle cx="390" cy="162" r="10" />
          <rect x="0" y="182" rx="5" ry="5" width="100%" height="2" />
          <rect x="0" y="194" rx="5" ry="5" width="90%" height="20" />
          <circle cx="390" cy="204" r="10" />
          <rect x="0" y="224" rx="5" ry="5" width="100%" height="2" />
        </ContentLoader>
      </Flex>
    </Flex>
  );
};

export default QuestionLoader;
