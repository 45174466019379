import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { CartType } from "../types/types";
import { CART_STORE } from "../constants/constant";
import { clearCart, getCartItemList } from "services/api/cart";

const initialCartState = {
  cartItemList: [],
  cartItemAOV: [],
  entryDate: undefined,
};

export const useCartStore = create(
  devtools(
    persist<CartType>(
      (set) => ({
        ...initialCartState,
        getCartItemList: async () => {
          try {
            const cartItemList = await getCartItemList();
            set(() => ({
              cartItemList,
            }));
          } catch (err: unknown) {
            console.error(err);
            throw err;
          }
        },
        clearCartItems: async () => {
          try {
            await clearCart();
            const cartItemList = await getCartItemList();
            set(() => ({
              cartItemList,
            }));
          } catch (err: unknown) {
            console.error(err);
            throw err;
          }
        },
        setEntryDate: (entryDate: string) => {
          set(() => ({
            entryDate,
          }));
        },
        setAOVCartItem: (cartItem) => {
          set(() => ({
            cartItemAOV: cartItem,
          }));
        },
      }),
      {
        name: CART_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
