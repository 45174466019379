import { FC } from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useColors } from "hooks/useColors";
import { usePartnerStore } from "store/partner";
import { KnabLogo } from "src/assets";

const FraudLoader: FC = () => {
  const { t } = useTranslation();
  const { getColor } = useColors();
  const { name } = usePartnerStore();

  return (
    <Box
      width="full"
      pos="fixed"
      bg={getColor("loader-bg", "accent-negative-1")}
      left={0}
      top={0}
      height="full"
      zIndex="banner"
      color={"white"}
      textAlign="center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 -10 900 700"
        fill="none"
      >
        <path
          stroke={getColor("loader-illustration", "accent-positive-1")}
          opacity={0.2}
          strokeLinecap="square"
          strokeWidth={48}
          d="M173.068 668C14.408 570.792-131.991 77.137 375.232 27.665 882.455-21.806 1012.6 443.416 794.267 585.171c-226.295 146.922-704.92 34.892-704.92-173.46"
        />
        <motion.path
          stroke={getColor("loader-illustration", "accent-positive-1")}
          initial={{ pathLength: 0 }}
          transition={{
            duration: 3,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
          animate={{ pathLength: 1 }}
          strokeLinecap="square"
          strokeWidth={48}
          d="M173.068 668C14.408 570.792-131.991 77.137 375.232 27.665 882.455-21.806 1012.6 443.416 794.267 585.171c-226.295 146.922-704.92 34.892-704.92-173.46"
        />
      </svg>

      <Text
        pos="fixed"
        as={"span"}
        color={getColor("loader-text")}
        left="50%"
        top="50%"
        fontSize={[20, 20, 60]}
        lineHeight={1}
        fontWeight="bold"
        transform="translate(-50%, -50%)"
      >
        <Box justifyContent="center" display="flex" mb={[1, 1, 6]}>
          {name.toLocaleLowerCase() === "knab" ? (
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width={235}
              height={100}
              initial={{ opacity: 0.8 }}
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: "backInOut",
              }}
              animate={{ opacity: 1 }}
              fill="none"
            >
              <rect width={235} height={100} fill="url(#a)" rx={24} />
              <defs>
                <pattern
                  id="a"
                  width={1}
                  height={1}
                  patternContentUnits="objectBoundingBox"
                >
                  <use
                    xlinkHref="#b"
                    transform="matrix(.00068 0 0 .00153 -.626 0)"
                  />
                </pattern>
                <image xlinkHref={KnabLogo} id="b" width={2400} height={654} />
              </defs>
            </motion.svg>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={65}
                height={66}
                fill="none"
                style={{ position: "relative", right: "-88px", top: "-25px" }}
              >
                <motion.path
                  initial={{ opacity: 0.3 }}
                  transition={{
                    repeat: Infinity,
                    duration: 4,
                    delay: 1,
                    ease: "backInOut",
                  }}
                  animate={{ opacity: 1 }}
                  fill={getColor("loader-illustration", "accent-positive-3")}
                  d="M13 0L16.5112 10.2187L26 14L16.5112 17.7813L13 28L9.48882 17.7813L0 14L9.48882 10.2187L13 0Z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={65}
                height={66}
                style={{ marginRight: "-40px", marginTop: "-10px" }}
                fill="none"
              >
                <motion.path
                  initial={{ opacity: 0.5 }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                    delay: 2,
                    ease: "backInOut",
                  }}
                  animate={{ opacity: 1 }}
                  fill={getColor("loader-illustration", "accent-positive-3")}
                  d="M17.698 0.601936L21.9205 12.257L33.8435 16.7662L21.7581 20.8201L17.0968 32.3067L12.8743 20.6517L0.951315 16.1424L13.0367 12.0885L17.698 0.601936Z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={65}
                height={66}
                fill="none"
              >
                <motion.path
                  initial={{ opacity: 0.1 }}
                  transition={{
                    repeat: Infinity,
                    duration: 3,
                    ease: "backInOut",
                  }}
                  animate={{ opacity: 1 }}
                  fill={getColor("loader-illustration", "accent-positive-3")}
                  d="M19.5 0L24.7668 14.2332L39 19.5L24.7668 24.7668L19.5 39L14.2332 24.7668L0 19.5L14.2332 14.2332L19.5 0Z"
                />
              </svg>
            </>
          )}
        </Box>
        {t("insurance.additional.checking-application")}
      </Text>
    </Box>
  );
};

export default FraudLoader;
