import {
  AddressType,
  PutCustomProfessionType,
  PutProfessionType,
  PutUserCocType,
  PutUserDOBType,
  PutUserPaymentConcentType,
  UserType,
  putFormalitiesType,
} from "src/types";
import { instance } from "./helpers/axios";
import { formatToastErrors } from "@utils/toast-errors";

export const getUserDetails = async () => {
  try {
    const { data } = await instance.get("/users/me");
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const updateUserDetails = async (
  payload:
    | UserType
    | Pick<UserType, "aov_date_of_birth" | "date_of_birth">
    | Pick<UserType, "first_name" | "last_name" | "email" | "phone_number">
    | PutProfessionType
    | PutUserCocType
    | PutUserPaymentConcentType
    | PutCustomProfessionType
    | putFormalitiesType
    | PutUserDOBType
) => {
  try {
    const { data } = await instance.patch("/users/me", payload);
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const createUserAddress = async (payload: AddressType) => {
  try {
    const { data } = await instance.post("/addresses", payload);
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const updateUserAddress = async (
  payload: AddressType,
  currentAddressId: string
) => {
  try {
    const { data } = await instance.patch(
      `/addresses/${currentAddressId}`,
      payload
    );
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const requestUserLogin = async (email: string) => {
  try {
    const { data } = await instance.post("/magic-link", { email: email });
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
