import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { media } from "@utils/media";
import { BackgroundProps } from ".";

export const ConfettiBackdrop: FC<BackgroundProps> = ({ color = "blue" }) => {
  return (
    <Box
      zIndex={-1}
      padding={0}
      pos="absolute"
      left="none"
      width="100%"
      height="100%"
      top="none"
      sx={{
        display: "block",
        [media.desktop]: {
          display: "none",
        },
      }}
      overflow="hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="100%"
        width="100%"
        viewBox="0 0 1338 815"
        preserveAspectRatio="none"
      >
        <motion.path
          d="M923.308 0.000608856L951.196 11.0597C967.674 17.5944 984.09 25.7819 999.327 33.3813C1000.69 34.059 1002.04 34.7319 1003.37 35.3987C1020.25 43.7997 1035.9 51.4138 1051.54 57.1503C1051.99 57.3135 1054.35 58.2332 1056.25 58.9744L1056.85 59.21C1058.01 59.6591 1059.15 60.1054 1060.01 60.4392L1061.43 60.9949C1061.43 60.9949 1061.43 60.995 1050.54 88.9463L1061.43 60.9949L1089.39 71.8911L1067.59 127.794L1039.64 116.898L1038.22 116.344C1037.37 116.011 1036.22 115.566 1035.08 115.118C1034.34 114.83 1033.66 114.568 1033.08 114.341C1031.59 113.76 1030.7 113.414 1030.88 113.481C1011.74 106.46 993.351 97.4326 976.634 89.1104C975.414 88.5028 974.203 87.8995 973.002 87.3009C957.239 79.445 943.064 72.3805 929.078 66.8341L901.19 55.7751L923.308 0.000608856Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M1113.57 388.248L1142.73 381.214C1159.97 377.058 1178.1 374.288 1194.93 371.717C1196.43 371.488 1197.92 371.26 1199.4 371.033C1218.03 368.174 1235.21 365.377 1251.3 361.051C1251.76 360.928 1254.22 360.32 1256.2 359.829L1256.83 359.673C1258.03 359.375 1259.22 359.08 1260.12 358.859L1261.6 358.492C1261.6 358.492 1261.6 358.492 1268.81 387.614L1261.6 358.492L1290.72 351.288L1305.13 409.532L1276.01 416.736L1274.53 417.102C1273.64 417.323 1272.45 417.617 1271.25 417.913C1270.48 418.104 1269.78 418.279 1269.17 418.429C1267.62 418.815 1266.69 419.044 1266.88 418.994C1247.19 424.288 1226.96 427.507 1208.5 430.339C1207.16 430.546 1205.82 430.75 1204.49 430.954C1187.08 433.618 1171.43 436.014 1156.8 439.541L1127.64 446.575L1113.57 388.248Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M139.231 414.271C100.905 395.864 64.219 379.58 36.8758 371.888L7.99666 363.764L24.2445 306.006L53.1236 314.13C86.1104 323.409 127.114 341.89 165.207 360.186C180.662 367.608 195.57 374.963 209.626 381.898C231.49 392.685 251.292 402.455 267.879 409.877L285.333 417.687L260.826 472.454L231.479 459.322L231.527 459.214C216.516 452.197 199.266 443.692 181.346 434.857C167.467 428.014 153.185 420.973 139.231 414.271Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M181.847 730.874C220.028 712.166 255.525 693.433 278.492 676.718L302.748 659.065L338.054 707.578L313.798 725.231C286.091 745.395 246.195 766.16 208.247 784.754C192.851 792.297 177.846 799.453 163.699 806.201C141.693 816.696 121.763 826.201 105.666 834.634L88.7282 843.508L60.8838 790.36L89.3642 775.439L89.4188 775.543C104.222 768.098 121.579 759.814 139.61 751.209C153.576 744.543 167.946 737.685 181.847 730.874Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M136.002 67.0444L164.531 57.7679C181.389 52.2865 199.254 48.1174 215.836 44.2478C217.314 43.9027 218.783 43.56 220.239 43.219C238.593 38.9219 255.502 34.7997 271.206 29.2384C271.653 29.0801 274.057 28.2823 275.995 27.6391L276.611 27.4347C277.785 27.0449 278.952 26.6579 279.825 26.3684L281.277 25.8868C281.277 25.8867 281.277 25.8866 290.72 54.3617L281.277 25.8868L309.752 16.4437L328.638 73.3939L300.163 82.8368L298.715 83.317C297.845 83.6058 296.682 83.9916 295.512 84.38C294.758 84.63 294.071 84.8583 293.478 85.0554C291.957 85.5607 291.053 85.861 291.236 85.7964C272.015 92.6031 252.099 97.3825 233.917 101.639C232.589 101.95 231.272 102.258 229.965 102.563C212.815 106.571 197.393 110.175 183.084 114.827L154.555 124.104L136.002 67.0444Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M1193.97 768.307C1152.42 759.277 1112.96 751.933 1084.58 750.783L1054.61 749.569L1057.04 689.618L1087.01 690.832C1121.25 692.22 1165.42 700.701 1206.71 709.676C1223.47 713.317 1239.67 717.02 1254.95 720.511C1278.72 725.94 1300.25 730.858 1318.1 734.237L1336.89 737.792L1325.74 796.746L1294.14 790.768L1294.17 790.652C1277.94 787.302 1259.19 783.023 1239.71 778.579C1224.62 775.137 1209.1 771.595 1193.97 768.307Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
