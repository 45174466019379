import { createIcon } from "@chakra-ui/icons";

export const ShoppingCartIcon = ({ color = "#006A70" }: { color?: string }) =>
  createIcon({
    displayName: "ShoppingCartIcon",
    viewBox: "0 0 68 74",
    path: (
      <path
        d="M20.6002 73.4001C19.0669 73.4001 17.7842 72.8828 16.7522 71.8481C15.7175 70.8161 15.2002 69.5334 15.2002 68.0001C15.2002 66.4668 15.7175 65.1841 16.7522 64.1521C17.7842 63.1174 19.0669 62.6001 20.6002 62.6001C22.1335 62.6001 23.4175 63.1174 24.4522 64.1521C25.4842 65.1841 26.0002 66.4668 26.0002 68.0001C26.0002 69.5334 25.4842 70.8161 24.4522 71.8481C23.4175 72.8828 22.1335 73.4001 20.6002 73.4001ZM57.4002 73.4001C55.8669 73.4001 54.5842 72.8828 53.5522 71.8481C52.5175 70.8161 52.0002 69.5334 52.0002 68.0001C52.0002 66.4668 52.5175 65.1841 53.5522 64.1521C54.5842 63.1174 55.8669 62.6001 57.4002 62.6001C58.9335 62.6001 60.2162 63.1174 61.2482 64.1521C62.2829 65.1841 62.8002 66.4668 62.8002 68.0001C62.8002 69.5334 62.2829 70.8161 61.2482 71.8481C60.2162 72.8828 58.9335 73.4001 57.4002 73.4001ZM14.2002 11.4001L25.2002 34.6001H51.3002C51.9002 34.6001 52.4335 34.4494 52.9002 34.1481C53.3669 33.8494 53.7669 33.4334 54.1002 32.9001L64.4002 14.2001C64.8002 13.4668 64.8335 12.8161 64.5002 12.2481C64.1669 11.6828 63.6002 11.4001 62.8002 11.4001H14.2002ZM12.8002 8.6001H62.8002C64.6002 8.6001 65.9495 9.3161 66.8482 10.7481C67.7495 12.1828 67.8002 13.6668 67.0002 15.2001L56.4002 34.6001C55.8669 35.4668 55.1842 36.1494 54.3522 36.6481C53.5175 37.1494 52.6002 37.4001 51.6002 37.4001H23.4002L18.4002 46.6001C17.8669 47.4001 17.8509 48.2668 18.3522 49.2001C18.8509 50.1334 19.6002 50.6001 20.6002 50.6001H62.8002V53.4001H20.6002C18.4669 53.4001 16.8842 52.5174 15.8522 50.7521C14.8175 48.9841 14.8002 47.2001 15.8002 45.4001L22.0002 34.4001L7.2002 3.4001H0.200195V0.600098H9.0002L12.8002 8.6001Z"
        fill={color}
      />
    ),
  });
