import EN from "../locales/en-gb/translation.json";
import NL from "../locales/nl-nl/translation.json";
import FR from "../locales/fr-fr/translation.json";
import DE from "../locales/de-de/translation.json";

type LanguageMap = {
  [langur: string]: {
    lng: string;
    lanFile: unknown;
    webLabel: string;
    mobileLabel: string;
  };
};

export const languageMap: LanguageMap = {
  "nl-nl": {
    lng: "nl",
    lanFile: NL,
    webLabel: "Nederlands",
    mobileLabel: "NL",
  },
  "en-gb": { lng: "en", lanFile: EN, webLabel: "English", mobileLabel: "EN" },
  "de-de": { lng: "de", lanFile: DE, webLabel: "Deutsch", mobileLabel: "DE" },
  "fr-fr": { lng: "fr", lanFile: FR, webLabel: "French", mobileLabel: "FR" },
};

export const SELECTED_LANGUAGE = "selectedLanguage";
