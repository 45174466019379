import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { PoliciesType } from "../types/types";
import { POLICIES_STORE } from "../constants/constant";

const initialPoliciesState = {
  policies: [],
};

export const usePoliciesStore = create(
  devtools(
    persist<PoliciesType>(
      (set) => ({
        ...initialPoliciesState,
        setPolicies: (data: any) => {
          try {
            set(() => ({
              policies: data,
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        clearPolicies: () => {
          set(() => initialPoliciesState);
        },
      }),
      {
        name: POLICIES_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
