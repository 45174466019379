import * as React from "react";
import * as Sentry from "@sentry/react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { rem } from "polished";
import { useNavigate } from "react-router-dom";

export const SentryFallback: React.FC<SentryFallbackProps> = ({ eventId }) => {
  const navigate = useNavigate();

  const onNavigateBack = () => {
    navigate("/");
    window.location.reload();
  };

  const handleDialog = (eventId: string | null) => {
    if (!eventId) return;
    Sentry.showReportDialog({
      eventId,
      lang: "nl",
      title: "Er ging iets mis",
      subtitle:
        "Bedankt dat je ons wilt helpen met het oplossen van dit probleem.",
      subtitle2: "Vul het formulier in om ons te helpen het op te lossen!",
      labelName: "Naam",
      labelEmail: "E-mail",
      labelClose: "Annuleren",
      labelComments: "Wat is er gebeurd?",
      labelSubmit: "Verstuur het crash-rapport",
      successMessage:
        "Bedankt voor je bijdrage om het platform te verbeteren! We gaan er z.s.m. naar kijken.",
    });
  };

  return (
    <Flex h="100vh" justifyContent="center" bg="white">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={800}
      >
        <Text fontSize={rem(28)} fontWeight="bold" margin={0}>
          Er ging iets mis
        </Text>
        <Text fontSize={rem(20)} mt={rem(20)}>
          Er gaat iets niet helemaal goed, we gaan er achter aan! Om ons te
          helpen kun je ook een melding maken van het probleem. Vertel ons wat
          er gebeurd is, dan gaan we het zo snel mogelijk oplossen.
        </Text>
        <Flex direction="row" gap={rem(45)} mt={rem(20)}>
          <Button
            variant="outline"
            onClick={() => handleDialog(eventId)}
            data-testid="sentry-btn"
            bg="white"
            h={rem(40)}
            borderRadius={rem(20)}
            px={rem(20)}
            py={rem(10)}
            borderWidth={rem(1)}
            cursor="pointer"
            _hover={{
              bg: "black",
              color: "white",
            }}
            fontSize={rem(16)}
          >
            Meld fout
          </Button>
          <Button
            variant="primary"
            onClick={onNavigateBack}
            data-testid="sentry-btn"
            bg="white"
            h={rem(40)}
            borderRadius={rem(20)}
            px={rem(20)}
            py={rem(10)}
            borderWidth={rem(1)}
            cursor="pointer"
            _hover={{
              bg: "black",
              color: "white",
            }}
            fontSize={rem(16)}
          >
            Ga terug
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

type SentryFallbackProps = {
  eventId: string | null;
};

export const SentryBoundary: React.FC<SentryBoundaryProps> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) => <SentryFallback eventId={eventId} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

type SentryBoundaryProps = {
  children: React.ReactNode;
};
