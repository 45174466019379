import { CartItemType } from "src/types";
import { instance } from "./helpers/axios";
import { formatToastErrors } from "@utils/toast-errors";

export const addProductToCart = async (productId: string) => {
  try {
    await instance.post(`/cart-item`, {
      insurance_product: productId,
    });
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const getCartItemList = async (): Promise<CartItemType[]> => {
  try {
    const { data } = await instance.get(`/cart-item`);
    return data.result;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const deleteProductFromCart = async (cartItemId: string) => {
  try {
    await instance.delete(`/cart-item/${cartItemId}`);
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const clearCart = async () => {
  try {
    await instance.post(`/cart-item/clear`);
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
