import { Header } from "alicia-design-system";
import { type FC } from "react";
import { useLanguageTranslation } from "hooks/useLanguageTranslation";
import { usePartnerStore } from "store/partner";
import { hexToRGB } from "@utils/colors";
import { SupportActionEnum } from "src/types";
import { useTranslation } from "react-i18next";
import { useTrengo } from "hooks/useTrengo";
import { useColors } from "hooks/useColors";
import {
  isInsuranceCalculatorRoute,
  isInsuranceCheckInRoute,
} from "@utils/getFlowPaths";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "services/navigateTo";
import Cookies from "universal-cookie";
import { addDataLayerEvent, Events } from "@utils/dataLayer";

const cookies = new Cookies(null, {
  path: "/",
});
export const PublicHeader: FC<PublicHeaderProps> = ({
  backgroundColor = "white",
  textColor = "#13100D",
}) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { getColor } = useColors();
  const { selectedLanguage, languages, changeSelectedLanguage } =
    useLanguageTranslation();
  const { brand, support_email, support_phone_number, enable_visual_effects } =
    usePartnerStore();
  const { openTrengo } = useTrengo();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const insuranceCheckInRoute = isInsuranceCheckInRoute();

  const getLogoClickPath = () => {
    if (insuranceCalculatorRoute) {
      return "/insurance-calculator";
    } else if (insuranceCheckInRoute) {
      return "/insurance-checkin-welcome";
    } else {
      return "/welcome";
    }
  };

  return (
    <Header
      enableVisualEffects={enable_visual_effects}
      backgroundColor={backgroundColor}
      languageMenuitemColorHover={getColor("support-menu-text-hover")}
      textColor={textColor}
      imageSrc={brand?.logo_primary ?? ""}
      imageFallbackSrc={brand?.logo_secondary ?? ""}
      showLanguageSelector={languages?.length > 1}
      languageMenu={{
        languages: languages,
        language: selectedLanguage,
      }}
      setLanguage={(e) => {
        changeSelectedLanguage(e.value);
      }}
      hideMobileMenu
      showSupport
      containerZIndex={3}
      bottomBorderColor={hexToRGB(getColor("text") ?? "", "0.2")}
      onLogoClick={() => navigation(getLogoClickPath())}
      supportMenu={{
        supportMenuTitle: t("support.title") as string,
        supportMenuOptions: [
          {
            id: SupportActionEnum.CALL,
            title: support_phone_number ?? t("support.call.title"),
            description: t("support.call.description") as string,
            titlePrefix: t("support.call.titlePrefix") as string,
          },
          {
            id: SupportActionEnum.MAIL,
            title: support_email ?? t("support.mail.title"),
            description: t("support.mail.description") as string,
            titlePrefix: t("support.mail.titlePrefix") as string,
          },
          {
            id: SupportActionEnum.CHAT,
            title: t("support.chat.title") as string,
            description: t("support.chat.description") as string,
            titlePrefix: t("support.chat.titlePrefix") as string,
          },
          {
            id: SupportActionEnum.FAQ,
            title: t("support.faq.title") as string,
            description: t("support.faq.description") as string,
            titlePrefix: t("support.faq.titlePrefix") as string,
          },
        ],
        backgroundColor: getColor("support-menu-background"),
        itemColor: getColor("support-menu-background"),
        itemHoverColor: getColor("support-menu-hover"),
        itemTextColor: getColor("support-menu-text"),
        onOpenSupport: () => {
          addDataLayerEvent(
            Events.support,
            "",
            {},
            {
              event_flow: "alicia",
              event_detail: "open",
            }
          );
        },
        itemHoverTextColor: getColor("support-menu-text-hover"),
        onSupportMenuSelect: (e) => {
          addDataLayerEvent(
            Events.support,
            "",
            {},
            {
              event_flow: "alicia",
              event_detail: e,
            }
          );
          switch (e) {
            case SupportActionEnum.CALL:
              window.location.href =
                "tel://" +
                (support_phone_number ?? t("support.call.title")).replace(
                  /\s/g,
                  ""
                );
              break;
            case SupportActionEnum.MAIL:
              window.location.href =
                "mailto:" +
                (support_email ?? t("support.mail.title")).replace(/\s/g, "");
              break;
            case SupportActionEnum.CHAT:
              openTrengo("chat");
              break;
            case SupportActionEnum.FAQ:
              openTrengo("help_center");
              break;
            default:
              break;
          }
        },
      }}
      mobileBack={
        t("deeplink.dismiss") !== "deeplink.dismiss"
          ? {
              title: t("header.routes.backToApp"),
              onClick: () =>
                cookies.get("platform") !== "web"
                  ? navigateTo(t("deeplink.dismiss"))
                  : navigateTo(t("header.routes.backToWeb")),
              textColor: getColor("support-menu-text"),
            }
          : undefined
      }
      headerMenuColors={{
        mobileMenuBackgroundColor: getColor("support-menu-background"),
        mobileMenuTextColor: getColor("support-menu-text"),
        menuBackgroundColor: getColor("support-menu-background"),
        menuTextColor: getColor("support-menu-text"),
        itemColor: getColor("support-menu-background"),
        itemHoverColor: getColor("support-menu-hover"),
        itemTextColor: getColor("support-menu-text"),
        itemHoverTextColor: getColor("support-menu-text-hover"),
      }}
    />
  );
};

interface PublicHeaderProps {
  backgroundColor?: string;
  textColor?: string;
}
