import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { media } from "@utils/media";
import Ticker from "framer-motion-ticker";
import { useColors } from "hooks/useColors";
import { AovCheckIcon } from "icons";
import { rem } from "polished";
import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { featureFlags } from "services/featureFlags";

const TICKER_STEPS = [
  "USPHeaderTicker.one",
  "USPHeaderTicker.two",
  "USPHeaderTicker.three",
];

const BannerTicker: FC = () => {
  const { getColor } = useColors();
  const isTickerEnabled = featureFlags.IsTickerEnabled();
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!isTickerEnabled) {
    return null;
  }

  return (
    <Flex
      borderBottom={`${rem(1)} solid ${getColor("question-border-color")}`}
      py={rem(12)}
    >
      {isMobile ? (
        <Ticker duration={25}>
          {TICKER_STEPS.map((item, index) => (
            <Fragment key={index}>
              <TickerItem value={item} />
            </Fragment>
          ))}
        </Ticker>
      ) : (
        <Flex w="100%" justifyContent="space-around" px={rem(24)}>
          {TICKER_STEPS.map((item, index) => (
            <Fragment key={index}>
              <TickerItem value={item} />
            </Fragment>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const TickerItem: FC<TickerItemProps> = ({ value }) => {
  const { t } = useTranslation();
  // icons
  const IconCheck = AovCheckIcon({});
  return (
    <Flex
      direction="row"
      alignItems="center"
      gap={rem(4)}
      style={{
        height: "25px",
        width: "fit-content",
      }}
      sx={{
        marginRight: rem(200),
        [media.tablet]: {
          marginRight: rem(20),
        },
      }}
    >
      <IconCheck boxSize={rem(24)} />
      <Text
        sx={{
          minWidth: "30%",
          [media.tablet]: {
            minWidth: "75%",
          },
        }}
        dangerouslySetInnerHTML={{
          __html: t(value) as string,
        }}
      />
    </Flex>
  );
};

interface TickerItemProps {
  value: string;
}

export default BannerTicker;
