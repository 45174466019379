import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { media } from "@utils/media";
import { BackgroundProps } from ".";

export const RightAngleArrowBackdrop: FC<BackgroundProps> = ({
  color = "blue",
  height = "100%",
  left = "none",
}) => {
  return (
    <Box
      zIndex={-1}
      padding={0}
      pos="absolute"
      left={left}
      width="auto"
      height={height}
      top="none"
      bottom="0"
      sx={{
        display: "block",
        [media.desktop]: {
          display: "none",
        },
      }}
      overflow="hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="100%"
        width="100%"
        viewBox="0 0 556 646"
        preserveAspectRatio="none"
      >
        <motion.path
          d="M 0 0 M 194.216 376.02 C 104.091 443.263 1.2553 529.017 -1 525 L -3 435 C 198.064 285.764 239.813 256.871 270.124 236.51 C 285.288 226.324 297.611 218.259 306.172 212.723 C 310.452 209.955 313.793 207.818 316.08 206.363 C 317.223 205.636 318.103 205.079 318.705 204.699 L 319.398 204.262 L 319.654 204.102 C 319.659 204.098 319.663 204.096 338.297 233.742 C 356.93 263.389 356.932 263.388 356.932 263.387 L 356.761 263.494 L 356.168 263.869 C 355.632 264.207 354.821 264.72 353.747 265.403 C 351.6 266.769 348.405 268.812 344.277 271.482 C 336.02 276.822 324.037 284.664 309.243 294.601 C 279.637 314.488 238.863 342.708 194.216 376.02 Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M335.792 534.017L322.891 501.531C312.828 476.187 309.554 444.147 309.029 413.524C308.49 382.082 310.838 348.48 314.086 318.07C316.956 291.205 320.583 266.342 323.738 246.942C302.446 246.446 274.679 245.803 245.024 245.125C180.807 243.657 107.853 242.03 72.5479 241.386L37.6074 240.748L38.8361 170.867L73.7766 171.505C109.276 172.154 182.406 173.785 246.574 175.252C278.677 175.985 308.566 176.679 330.429 177.189C341.361 177.444 350.286 177.653 356.479 177.798L366.131 178.025C366.132 178.025 366.133 178.025 365.333 212.961L366.131 178.025L399.561 219.997C399.567 219.998 399.567 219.998 399.567 219.998L399.566 220.003L399.549 220.086L399.476 220.451C399.408 220.786 399.305 221.305 399.168 221.996C398.895 223.378 398.492 225.452 397.985 228.139C396.97 233.516 395.542 241.338 393.918 250.985C390.665 270.317 386.652 296.803 383.583 325.54C380.501 354.386 378.439 384.873 378.911 412.373C379.396 440.694 382.555 462.449 387.847 475.774L400.747 508.261L335.792 534.017Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
