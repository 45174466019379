import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ProductListType, ProductType } from "../types/types";
import { PRODUCTS_STORE } from "../constants/constant";

const initialProductsState = {
  products: [],
  isProductsAvailable: false,
  isPoliciesAvailable: false,
  isPastPoliciesAvailable: false,
};

export const useProductsStore = create(
  devtools(
    persist<ProductListType>(
      (set) => ({
        ...initialProductsState,
        setProducts: async (data: any) => {
          try {
            set(() => ({
              products: data,
              isProductsAvailable: data.some(
                (product: ProductType) => !product?.active_policies?.length
              ),
            }));
          } catch (err: any) {
            const error = err || "Unexpected error.";
            throw error;
          }
        },
        setIsPoliciesAvailable: (available: boolean) => {
          set(() => ({
            isPoliciesAvailable: available,
          }));
        },
        setIsPastPoliciesAvailable: (available: boolean) => {
          set(() => ({
            isPastPoliciesAvailable: available,
          }));
        },
        clearProducts: () => {
          set(() => ({
            products: [],
          }));
        },
      }),
      {
        name: PRODUCTS_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
