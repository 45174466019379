import { getLocalPartner } from "@utils/getLocalPartner";

export const createApiEnvUrl = (
  env?: string | "qa" | "stage" | "production",
  type?: "/api" | "/auth",
  protocol?: "http" | "https" | "ws" | "wss",
  isAdmin?: boolean
) => {
  const host: string = window.location.host;
  if (!env && !protocol)
    return `https://${!isAdmin ? getLocalPartner() : ""}${
      !isAdmin ? "-" : ""
    }connect.qa.aliciatech.nl${type || ""}`;

  protocol = protocol || "https";
  const [partner = getLocalPartner(), environment = env ?? "qa"] =
    host.split(".");

  if (env === "production") {
    return `${protocol}://${!isAdmin ? partner : ""}${
      !isAdmin ? "-" : ""
    }connect.alicia.insure${type || ""}`;
  }
  return `${protocol}://${
    !isAdmin ? (host.includes(":3000") ? getLocalPartner() : partner) : ""
  }${!isAdmin ? "-" : ""}connect.${environment}.aliciatech.nl${type || ""}`;
};
