export const CustomErrorCodes = {
  loginFailed: {
    code: 35001,
    message:
      "nloggen mislukt. Je inloggegevens kloppen niet. Probeer het nog eens.",
  }, // login credential failed
  emailExist: {
    code: 35011,
    message:
      "E-mailadres bestaat al. Kies een ander e-mailadres of <a style='text-decoration:underline;' href='/sign-in'>log in op je account</a>.",
  }, // email already exists
  400: {
    code: 35002,
    message: "Sorry, er is iets fout gegaan. Probeer het later nog eens.",
  }, // bad request
  401: {
    code: 35003,
    message:
      "Sorry, er is iets fout gegaan. Onze mensen gaan het tot op de bodem uitzoeken.",
  }, // unauthorized
  403: {
    code: 35004,
    message:
      "Sorry, er is iets fout gegaan. Onze mensen gaan het tot op de bodem uitzoeken.",
  }, // forbidden
  404: { code: 35005, message: "Sorry, deze pagina bestaat niet (meer)." }, // not found
  505: { code: 35006, message: "Sorry, we kunnen je verzoek niet verwerken." }, // not supported
  500: {
    code: 35007,
    message: "Sorry, er is iets fout gegaan. Probeer het later nog eens.",
  }, // internal server error
  unknown: {
    code: 35010,
    message: "Sorry, er is iets fout gegaan. Probeer het later nog eens.",
  },
};
