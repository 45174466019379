import { createIcon } from "@chakra-ui/icons";

export const UserIcon = ({ fillColor }: { fillColor: string }) =>
  createIcon({
    displayName: "UserIcon",
    viewBox: "0 0 20 20",
    path: (
      <path
        d="M10.0001 9.33337C9.38133 9.33337 8.85164 9.11306 8.41102 8.67244C7.97039 8.23181 7.75008 7.70212 7.75008 7.08337C7.75008 6.46462 7.97039 5.93494 8.41102 5.49431C8.85164 5.05369 9.38133 4.83337 10.0001 4.83337C10.6188 4.83337 11.1485 5.05369 11.5891 5.49431C12.0298 5.93494 12.2501 6.46462 12.2501 7.08337C12.2501 7.70212 12.0298 8.23181 11.5891 8.67244C11.1485 9.11306 10.6188 9.33337 10.0001 9.33337ZM4.41675 15.1667V14.0834C4.41675 13.7778 4.5105 13.4896 4.698 13.2188C4.8855 12.948 5.13897 12.7362 5.45841 12.5834C6.2223 12.2223 6.98272 11.9514 7.73966 11.7709C8.49661 11.5903 9.25008 11.5 10.0001 11.5C10.7501 11.5 11.5036 11.5903 12.2605 11.7709C13.0174 11.9514 13.7779 12.2223 14.5417 12.5834C14.8612 12.7362 15.1147 12.948 15.3022 13.2188C15.4897 13.4896 15.5834 13.7778 15.5834 14.0834V15.1667H4.41675ZM5.00008 14.5834H15.0001V14.0834C15.0001 13.8889 14.9341 13.7049 14.8022 13.5313C14.6702 13.3577 14.4862 13.2084 14.2501 13.0834C13.5834 12.7639 12.8911 12.5174 12.1733 12.3438C11.4554 12.1702 10.731 12.0834 10.0001 12.0834C9.26917 12.0834 8.54477 12.1702 7.82689 12.3438C7.10902 12.5174 6.41675 12.7639 5.75008 13.0834C5.51397 13.2084 5.32994 13.3577 5.198 13.5313C5.06605 13.7049 5.00008 13.8889 5.00008 14.0834V14.5834ZM10.0001 8.75004C10.4584 8.75004 10.8508 8.58685 11.1772 8.26046C11.5036 7.93407 11.6667 7.54171 11.6667 7.08337C11.6667 6.62504 11.5036 6.23268 11.1772 5.90629C10.8508 5.5799 10.4584 5.41671 10.0001 5.41671C9.54175 5.41671 9.14939 5.5799 8.823 5.90629C8.49661 6.23268 8.33341 6.62504 8.33341 7.08337C8.33341 7.54171 8.49661 7.93407 8.823 8.26046C9.14939 8.58685 9.54175 8.75004 10.0001 8.75004Z"
        fill={fillColor}
      />
    ),
  });
