import { useNavigate } from "react-router-dom";
import { createApiEnvUrl } from "@utils";
import { getEnvironment } from "@utils/getEnvironment";

export const useWebsockets = () => {
  const navigate = useNavigate();
  const messageHandler = async (room_name: string) => {
    const baseUrl = createApiEnvUrl(getEnvironment(), undefined, "wss");
    const stripeIdentitySocket = new WebSocket(
      `${baseUrl}/stripe/identity/${room_name}/`
    );
    stripeIdentitySocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.status === "identity.verification_session.verified") {
        navigate("/result");
      }
      if (data.error) {
        navigate("/result?type=dropout");
      }
      //TODO: Handle the other types of error messages
      stripeIdentitySocket.close();
    };
  };

  return {
    messageHandler,
  };
};
