import { PartnerQueryType } from "src/types/types";
import { authInstance, adminAuthInstance } from "./helpers/axios";
import { formatToastErrors } from "@utils/toast-errors";

export const userAuthenticate = async (payload: PartnerQueryType) => {
  try {
    const { data } = await authInstance.post(
      "/jwt/create-by-external-id",
      payload
    );
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const refreshUserAuthenticate = async (
  refresh: string,
  isAdmin?: boolean
) => {
  try {
    const request = isAdmin ? adminAuthInstance : authInstance;
    const { data } = await request.post("/auth/jwt/refresh", {
      refresh,
    });
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
