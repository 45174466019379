export const sizes = {
  large: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480,
} as const;

export const media = {
  mobile: "@media (max-width: 480px)",
  tablet: "@media (max-width: 768px)",
  desktop: "@media (max-width: 992px)",
  large: "@media (max-width: 1200px)",
} as const;
