import { getEnvironment } from "@utils/getEnvironment";

export const getStripeKey = () => {
  switch (getEnvironment()) {
    case "production":
      return "pk_live_51Hi3HyCCz7eEyIhLDQdPo27oSGLXQtaXjQAW2WDKoIotxaNRbNqAxWakqEAlRDVNTXvKnL1J5lWV8fvBMex6QmJM00RVH8BBVK";
    default:
      return "pk_test_51Hi3HyCCz7eEyIhLZzyvzSEeA9sp6jftCtc4y3MForttvh6A8rikr4f95hkP9b3sHbA2vpJIFyl40lSBkH0dRzQg00886ruW2D";
  }
};

export const STRIPE_ACCOUNT = "acct_1Hi3HyCCz7eEyIhL";
