import { formatToastErrors } from "@utils/toast-errors";
import { instance } from "./helpers/axios";

export const createVerificationSession = async (
  product: string,
  start_date: string
) => {
  try {
    const { data } = await instance.post("/identity-verification", {
      product,
      start_date,
    });
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
