/* eslint-disable no-useless-escape */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\+?[0-9-() ]*$/;
export const PHONE_MAX_LENGTH = 12;
export const PHONE_MIN_LENGTH = 10;
export const PUBLIC_ROUTES = [
  "/404",
  "/500",
  "/sign-in",
  "/confirmation",
  "/invoice-reminder",
];
export const COOKIE_TOKEN = "admin_refresh_token";
