import { createIcon } from "@chakra-ui/icons";

export const CurrencyIcon = ({ fillColor }: { fillColor: string }) =>
  createIcon({
    displayName: "CurrencyIcon",
    viewBox: "0 0 20 20",
    path: (
      <path
        d="M12.5415 16.4167C10.9443 16.4167 9.59359 15.9757 8.48942 15.0938C7.38525 14.2118 6.62484 13.0973 6.20817 11.75H3.2915V11.1667H6.104C6.03456 10.8473 6.00331 10.4618 6.01025 10.0105C6.0172 9.55907 6.04845 9.16671 6.104 8.83337H3.2915V8.25004H6.24984C6.65262 6.90282 7.40262 5.78824 8.49984 4.90629C9.59706 4.02435 10.9443 3.58337 12.5415 3.58337C13.3332 3.58337 14.0832 3.70837 14.7915 3.95837C15.4998 4.20837 16.1457 4.5556 16.729 5.00004L16.3123 5.39587C15.7846 5.00699 15.2012 4.7049 14.5623 4.48962C13.9234 4.27435 13.2498 4.16671 12.5415 4.16671C11.0971 4.16671 9.88525 4.56254 8.90609 5.35421C7.92692 6.14587 7.24289 7.11115 6.854 8.25004H12.4165V8.83337H6.70817C6.63873 9.15282 6.604 9.54171 6.604 10C6.604 10.4584 6.63873 10.8473 6.70817 11.1667H12.4165V11.75H6.83317C7.23595 12.8889 7.92692 13.8542 8.90609 14.6459C9.88525 15.4375 11.0971 15.8334 12.5415 15.8334C13.2498 15.8334 13.9234 15.7257 14.5623 15.5105C15.2012 15.2952 15.7846 14.9931 16.3123 14.6042L16.729 15C16.1457 15.4445 15.4998 15.7917 14.7915 16.0417C14.0832 16.2917 13.3332 16.4167 12.5415 16.4167Z"
        fill={fillColor}
      />
    ),
  });
