import React, { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { BackgroundProps } from ".";

export const NotFoundBG: FC<BackgroundProps> = ({
  color = "blue",
  left = "none",
  right = "none",
  top = "none",
  bottom = "none",
  mobile = false,
  transition = false,
}) => {
  const duration = transition ? 1 : 0;
  return (
    <>
      {mobile ? (
        <Flex alignItems="center" justifyContent="center" w="inherit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="257"
            height="99"
            viewBox="0 0 257 99"
            fill="none"
          >
            <motion.path
              d="M109.439 15.5981C121.887 3.81797 169.396 2.6008 161.727 48.8684C154.058 95.1361 109.472 95.5882 102.031 72.7938C94.3183 49.1685 115.708 9.30185 134.288 14.2803"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M22.7001 10.1309L10.9546 52.6434H78.5174"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M63.1337 10.6504C59.08 32.6516 51.6792 80.8533 54.5065 97.6504"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M201.183 9.50586L189.438 52.0184H257"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M241.617 10.0254C237.563 32.0266 230.162 80.2283 232.989 97.0254"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
          </svg>
        </Flex>
      ) : (
        <Box
          zIndex={-1}
          pos="absolute"
          w="-webkit-fill-available"
          h="-webkit-fill-available"
          top={top}
          left={left}
          right={right}
          bottom={bottom}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
          >
            <motion.path
              d="M402.651 50.9267C449.497 6.59167 628.299 2.01083 599.437 176.141C570.574 350.27 402.772 351.972 374.767 266.185C345.742 177.27 426.244 27.2304 496.17 45.9671"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M76.2047 30.3477L32 190.345H286.275"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M228.379 32.3037C213.122 115.106 185.269 296.515 195.91 359.731"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M747.93 28L703.726 187.997H958"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M900.104 29.9561C884.847 112.758 856.994 294.167 867.635 357.384"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
          </svg>
        </Box>
      )}
    </>
  );
};
