import { createIcon } from "@chakra-ui/icons";

export const PolicyOverviewIcon = ({ fillColor }: { fillColor: string }) =>
  createIcon({
    displayName: "PolicyOverviewIcon",
    viewBox: "0 0 20 20",
    path: (
      <path
        d="M3.5835 9.16671V3.58337H9.16683V9.16671H3.5835ZM3.5835 16.4167V10.8334H9.16683V16.4167H3.5835ZM10.8335 9.16671V3.58337H16.4168V9.16671H10.8335ZM10.8335 16.4167V10.8334H16.4168V16.4167H10.8335ZM4.16683 8.58337H8.5835V4.16671H4.16683V8.58337ZM11.4168 8.58337H15.8335V4.16671H11.4168V8.58337ZM11.4168 15.8334H15.8335V11.4167H11.4168V15.8334ZM4.16683 15.8334H8.5835V11.4167H4.16683V15.8334Z"
        fill={fillColor}
      />
    ),
  });
