import { Flex } from "@chakra-ui/react";
import { media } from "@utils/media";
import { rem } from "polished";
import { FC } from "react";
import Placeholder from "../placeholder";
import HeaderLoader from "./header-loader";
import TitleLoader from "./title-loader";

const RootLoader: FC = () => {
  return (
    <Flex
      pos="fixed"
      top={0}
      left={0}
      bg="white"
      width="100%"
      flexDirection="column"
    >
      <HeaderLoader />
      <Flex flexDirection="column" mt={rem(24)} px={rem(24)}>
        <TitleLoader />
        <Flex
          sx={{
            marginTop: rem(12),
            [media.large]: {
              marginTop: rem(8),
            },
          }}
        >
          <Placeholder />
        </Flex>
        <Flex
          sx={{
            marginTop: rem(12),
            [media.large]: {
              marginTop: rem(8),
            },
          }}
        >
          <Placeholder />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RootLoader;
