import {
  // ClaimsIcon,
  CurrencyIcon,
  InboxIcon,
  // InboxIcon,
  PolicyOverviewIcon,
  UserIcon,
} from "icons";
import { useTranslation } from "react-i18next";
import { NavigationRouteType } from "src/types";

export const NavList = ({
  color,
}: {
  color: string;
}): NavigationRouteType[] => {
  const { t } = useTranslation();
  return [
    {
      id: "policyOverview",
      label: t("header.routes.myInsurances") ?? "Policy overview",
      route: "/user/policies",
      icon: PolicyOverviewIcon({ fillColor: color ?? "#13100D" }),
    },
    {
      id: "myDetails",
      label: t("header.routes.myDetails") ?? "My details",
      route: "/user/details",
      icon: UserIcon({ fillColor: color ?? "#13100D" }),
    },
    {
      id: "invoices",
      label: t("header.routes.myInvoices") ?? "Invoices",
      route: "/user/invoices",
      icon: CurrencyIcon({ fillColor: color ?? "#13100D" }),
    },
    // {
    //   id: "claims",
    //   label: t("header.routes.myClaims") ?? "Claims",
    //   route: "/user/claims",
    //   icon: ClaimsIcon({ fillColor: color ?? "#13100D" }),
    // },
    {
      id: "inbox",
      label: t("header.routes.myInbox") ?? "Inbox",
      route: "/user/inbox",
      icon: InboxIcon({ fillColor: color ?? "#13100D" }),
    },
  ];
};
