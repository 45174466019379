import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { media } from "@utils/media";
import { BackgroundProps } from ".";

export const BackdropBG: FC<BackgroundProps> = ({ color = "blue" }) => {
  return (
    <Box
      zIndex={-1}
      padding={0}
      pos="absolute"
      left="none"
      width="100%"
      height="100%"
      top="none"
      sx={{
        display: "block",
        [media.desktop]: {
          display: "none",
        },
      }}
      overflow="hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="100%"
        width="100%"
        viewBox="0 0 783 483"
        preserveAspectRatio="none"
      >
        <motion.path
          d="M 1295.2816 3.0702 C 1305.9648 3.1038 1314.6176 6.695 1314.6048 11.0913 L 1313.3392 475.097 C 1313.328 479.494 1304.656 483.03 1293.9728 482.997 L 19.7934 478.987 C 9.1093 478.954 0.4579 475.363 0.4699 470.966 L 1.7389 7.9567 C 1.7509 3.5604 10.4218 0.0237 21.1058 0.0573 L 1295.2816 3.0702 Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
