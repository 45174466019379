import { intlMerge } from "src/utils/intlMerge";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { languageMap, SELECTED_LANGUAGE } from "src/utils/i18n";
import { usePartnerStore } from "store/partner";

type LanguageData = {
  loadingLanguage: boolean;
  translations: any;
  changeLanguage: (language: string) => void;
};
export const useLanguageSelector = (): LanguageData => {
  const [loading, setLoading] = useState(true);
  const { translations, default_language, languages } = usePartnerStore();

  useEffect(() => {
    i18n.use(initReactI18next).init({
      resources: {},
      lng: default_language?.code,
      fallbackLng: default_language?.code,
      lowerCaseLng: true,
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
      },
    });
  }, []);

  useEffect(() => {
    const setData = () => {
      const finalTranslations =
        JSON.stringify(translations) === "{}" ? languages : translations;
      for (const property in finalTranslations) {
        const lng = languageMap[property];
        const trans = finalTranslations?.[property].translation;
        if (trans) {
          if (lng && lng.lanFile) {
            i18n.addResourceBundle(
              lng.lng,
              "translation",
              intlMerge(lng.lanFile as keyof typeof lng.lanFile, trans),
              true,
              true
            );
          } else {
            i18n.addResourceBundle(property, "translation", trans, true, true);
          }
        }
      }
    };
    setData();
    setLoading(false);
  }, [translations]);

  const changeLanguage = (language: string) => {
    localStorage.setItem(SELECTED_LANGUAGE, language);
    i18n.changeLanguage(language);
  };

  return {
    loadingLanguage: loading,
    translations,
    changeLanguage,
  };
};
