import { createIcon } from "@chakra-ui/icons";

export const InboxIcon = ({ fillColor }: { fillColor: string }) =>
  createIcon({
    displayName: "InboxIcon",
    viewBox: "0 0 20 20",
    path: (
      <path
        d="M4.8335 17.2364C4.47238 17.2364 4.17377 17.1184 3.93766 16.8822C3.70155 16.6461 3.5835 16.3475 3.5835 15.9864V5.65308C3.5835 5.29197 3.70155 4.99335 3.93766 4.75724C4.17377 4.52113 4.47238 4.40308 4.8335 4.40308H15.1668C15.5279 4.40308 15.8266 4.52113 16.0627 4.75724C16.2988 4.99335 16.4168 5.29197 16.4168 5.65308V15.9864C16.4168 16.3475 16.2988 16.6461 16.0627 16.8822C15.8266 17.1184 15.5279 17.2364 15.1668 17.2364H4.8335ZM4.8335 16.6531H15.1668C15.3613 16.6531 15.521 16.5906 15.646 16.4656C15.771 16.3406 15.8335 16.1809 15.8335 15.9864V13.7364H12.7085C12.4307 14.2642 12.0522 14.6739 11.5731 14.9656C11.0939 15.2572 10.5696 15.4031 10.0002 15.4031C9.43072 15.4031 8.90641 15.2572 8.42725 14.9656C7.94808 14.6739 7.56961 14.2642 7.29183 13.7364H4.16683V15.9864C4.16683 16.1809 4.22933 16.3406 4.35433 16.4656C4.47933 16.5906 4.63905 16.6531 4.8335 16.6531ZM10.0002 14.8197C10.5279 14.8197 11.0071 14.667 11.4377 14.3614C11.8682 14.0559 12.1668 13.6531 12.3335 13.1531H15.8335V5.65308C15.8335 5.45863 15.771 5.29891 15.646 5.17391C15.521 5.04891 15.3613 4.98641 15.1668 4.98641H4.8335C4.63905 4.98641 4.47933 5.04891 4.35433 5.17391C4.22933 5.29891 4.16683 5.45863 4.16683 5.65308V13.1531H7.66683C7.8335 13.6531 8.13211 14.0559 8.56266 14.3614C8.99322 14.667 9.47238 14.8197 10.0002 14.8197ZM4.8335 16.6531H4.16683H15.8335H4.8335Z"
        fill={fillColor}
      />
    ),
  });
