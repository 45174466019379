import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { media } from "@utils/media";
import { BackgroundProps } from ".";

export const LeftAngleArrowBackdrop: FC<BackgroundProps> = ({
  color = "blue",
  height = "100%",
  left = "none",
  right = "none",
}) => {
  return (
    <Box
      zIndex={-1}
      padding={0}
      pos="absolute"
      left={left}
      right={right}
      width="auto"
      height={height}
      top="none"
      bottom="0"
      sx={{
        display: "block",
        [media.desktop]: {
          display: "none",
        },
      }}
      overflow="hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="100%"
        width="100%"
        viewBox="0 0 483 481"
        preserveAspectRatio="none"
      >
        <motion.path
          d="M530.491 415.114C438.243 379.132 327.405 304.007 237.869 235.981C193.514 202.282 155.122 170.897 127.827 147.942C125.175 145.712 122.629 143.562 120.193 141.497C129.445 140.102 139.125 138.54 149.043 136.8C179.166 131.516 212.108 124.484 242.176 115.275C271.46 106.305 301.347 94.3027 322.921 77.6258L350.576 56.2483L307.868 0.943357L280.213 22.3208C268.87 31.0897 248.836 40.139 221.753 48.4341C195.455 56.4889 165.586 62.9342 137.012 67.9466C108.546 72.94 81.9831 76.4054 62.505 78.622C52.7842 79.7283 44.8726 80.5185 39.4248 81.0295C36.702 81.2849 34.5975 81.4702 33.1935 81.5902C32.4916 81.6501 31.9651 81.6938 31.6242 81.7216L31.2533 81.7515L31.1689 81.7582L31.1635 81.7587C31.1636 81.7587 31.1638 81.7587 31.1644 81.7646L0.0684854 125.495L2.51825 134.833C4.09036 140.825 6.35658 149.46 9.13332 160.037C14.6866 181.189 22.2822 210.105 30.4509 241.16C46.7785 303.233 65.4258 373.965 74.6155 408.26L83.6606 442.015L151.158 423.88L142.113 390.124C132.973 356.017 114.37 285.455 98.0304 223.335C97.0073 219.445 95.9931 215.589 94.9909 211.778C121.702 233.992 156.33 261.99 195.548 291.786C285.684 360.268 403.061 440.587 504.986 480.344L530.491 415.114ZM33.8641 116.605L0.0684854 125.495C0.068023 125.493 0.0677992 125.492 33.8641 116.605Z"
          fill={color}
          stroke={color}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
