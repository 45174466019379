import { AnswerType } from "src/types";
import { instance } from "./helpers/axios";
import { FileState } from "alicia-design-system";
import axios from "axios";
import { getEnvironment } from "@utils/getEnvironment";
import { formatToastErrors } from "@utils/toast-errors";

export const upsertAnswer = async (
  answer: AnswerType,
  type?: "freelancer" | "supplier"
) => {
  try {
    const apiCall = answer.id ? instance.patch : instance.post;
    const { data } = await apiCall(
      answer.id ? `/answers/${answer.id}` : "/answers",
      {
        option: answer.option,
        question: answer.question,
        date_value: answer.date_value,
        money_value: answer.money_value,
        float_value: answer.float_value,
        text_value: answer.text_value,
        files: answer.files,
        multi_select_options: answer.multi_select_options,
      },
      {
        params: {
          type,
        },
        paramsSerializer: { indexes: null },
      }
    );
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const getPreviousAnswers = async (questionId: string) => {
  try {
    const { data } = await instance.get("/answers", {
      params: { question: questionId },
    });

    const answers = data.result.map((answer: any) => ({
      id: answer.id,
      option: answer.option?.id,
      question: answer?.question,
      override: answer?.override,
    }));
    return answers;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const postAdditionalDetailsAnswer = async (
  answer: string,
  reason: string,
  additionalAnswerId?: string
) => {
  try {
    const apiCall = additionalAnswerId ? instance.patch : instance.post;
    const { data } = await apiCall(
      additionalAnswerId
        ? `/additional-answer-details/${additionalAnswerId}`
        : "/additional-answer-details",
      {
        reason,
        answer,
      }
    );
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const postDocuments = async (files: FileState[], userId?: string) => {
  try {
    const data = new FormData();
    files.forEach((file: File) => {
      data.append("file", file);
    });
    const result = await instance.post("documents", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    axios.defaults.timeout = 25000;

    if (getEnvironment() === "production") {
      data.append("user_id", userId || "");
      axios.post(
        "https://stpx7fbon6.execute-api.eu-central-1.amazonaws.com/dev/upload",
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    }

    return result.data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
