import { EditorColorGroupProps } from "../features/editor/types";

export const GLOBAL_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Text",
    title: "Title",
    key: "title",
    global: true,
  },
  {
    group: "Text",
    title: "Sub title",
    key: "subtitle",
    global: true,
  },
  {
    group: "Text",
    title: "Default",
    key: "text",
    global: true,
  },
  {
    group: "Loader",
    title: "Text",
    key: "loader-text",
    global: true,
  },
  {
    group: "Loader",
    title: "Background",
    key: "loader-bg",
    global: true,
  },
  {
    group: "Loader",
    title: "Illustration",
    key: "loader-illustration",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Background",
    key: "button-solid-background",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Text",
    key: "button-solid-text",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Border",
    key: "button-solid-border",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Hover Background",
    key: "button-solid-hover-background",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Hover Text",
    key: "button-solid-hover-text",
    global: true,
  },
  {
    group: "Primary Button",
    title: "Hover Border",
    key: "button-solid-hover-border",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Background",
    key: "button-ghost-background",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Text",
    key: "button-ghost-text",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Border",
    key: "button-ghost-border",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Hover Background",
    key: "button-ghost-hover-background",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Hover Text",
    key: "button-ghost-hover-text",
    global: true,
  },
  {
    group: "Outline Button",
    title: "Hover Border",
    key: "button-ghost-hover-border",
    global: true,
  },
  {
    group: "Background",
    title: "Body",
    key: "background-body",
    global: true,
  },
  {
    group: "Background",
    title: "Card",
    key: "background-card",
    global: true,
  },
  {
    group: "Background",
    title: "Number Marking Background",
    key: "number-marking-background",
    global: true,
  },
  {
    group: "Background",
    title: "Illustration",
    key: "background-illustration",
    global: true,
  },
  {
    group: "Background",
    title: "Confetti",
    key: "confetti-color",
    global: false,
  },
  {
    group: "Accent",
    title: "Positive-1",
    key: "accent-positive-1",
    global: true,
  },
  {
    group: "Accent",
    title: "Positive-2",
    key: "accent-positive-2",
    global: true,
  },
  {
    group: "Accent",
    title: "Positive-3",
    key: "accent-positive-3",
    global: true,
  },
  {
    group: "Accent",
    title: "Negative-1",
    key: "accent-negative-1",
    global: true,
  },
  {
    group: "Accent",
    title: "Negative-2",
    key: "accent-negative-2",
    global: true,
  },
  {
    group: "Accent",
    title: "Negative-3",
    key: "accent-negative-3",
    global: true,
  },
  {
    group: "Support Menu",
    title: "Background",
    key: "support-menu-background",
    global: true,
  },
  {
    group: "Support Menu",
    title: "Text",
    key: "support-menu-text",
    global: true,
  },
  {
    group: "Support Menu",
    title: "Hover Text",
    key: "support-menu-text-hover",
    global: true,
  },
  {
    group: "Support Menu",
    title: "Hover Background",
    key: "support-menu-hover",
    global: true,
  },
  {
    group: "Steps",
    title: "Text",
    key: "steps-text",
    global: true,
  },
  {
    group: "Steps",
    title: "Illustration Background",
    key: "steps-label-background",
    global: true,
  },
  {
    group: "Input",
    title: "Label Background",
    key: "input-label-background",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu icon",
    key: "nav-pane-icon-color",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item background",
    key: "nav-pane-item-bg",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item text",
    key: "nav-pane-item-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item active",
    key: "nav-pane-item-active-bg",
    fallbackKey: "input-label-background",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item active text",
    key: "nav-pane-item-active-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item hover background",
    key: "nav-pane-item-hover-bg",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item hover text",
    key: "nav-pane-item-hover-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item border",
    key: "nav-pane-item-border",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item counter background",
    key: "nav-pane-count-bg",
    fallbackKey: "background-illustration",
    global: true,
  },
];
export const PAGE_TITLE_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Text",
    title: "Page Title",
    key: "title",
    global: true,
  },
];
export const PRODUCT_CARD_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Product",
    title: "Card Background",
    key: "product-card-background",
    global: false,
  },
  {
    group: "Product",
    title: "Title",
    key: "product-title",
    global: false,
  },
  {
    group: "Product",
    title: "Description",
    key: "product-desc",
    global: false,
  },
  {
    group: "Product",
    title: "Price",
    key: "product-price",
    global: false,
  },
  {
    group: "Product Tag",
    title: "Text",
    key: "product-tag-text",
    global: false,
  },
  {
    group: "Product Tag",
    title: "Positive Background",
    key: "product-tag-positive",
    global: false,
  },
  {
    group: "Product Tag",
    title: "Negative Background",
    key: "product-tag-negative",
    global: false,
  },
  {
    group: "Read More",
    title: "Right Arrow Icon",
    key: "product-right-arrow",
    global: false,
  },
  {
    group: "Read More",
    title: "Shield Icon",
    key: "product-shield",
    global: false,
  },
  {
    group: "Read More",
    title: "Text",
    key: "product-readmore-text",
    global: false,
  },
  {
    group: "List",
    title: "Check Icon",
    key: "product-list-check-icon",
    global: false,
  },
];
export const CART_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Cart",
    title: "Card Background",
    key: "cart-card-background",
    global: false,
  },
  {
    group: "Cart",
    title: "Card Text",
    key: "cart-card-text",
    global: false,
  },
  {
    group: "Cart",
    title: "Card Seperator",
    key: "cart-card-seperator",
    global: false,
  },
  {
    group: "Cart",
    title: "Title",
    key: "cart-title",
    global: false,
  },
  {
    group: "Cart",
    title: "Empty Description",
    key: "cart-empty-description",
    global: false,
  },
  {
    group: "Cart",
    title: "Empty Icon",
    key: "cart-empty-icon",
    global: false,
  },
  {
    group: "Cart",
    title: "Empty Background",
    key: "cart-empty-background",
    global: false,
  },
  {
    group: "Cart",
    title: "Counter Background",
    key: "cart-counter-background",
    global: false,
  },
  {
    group: "Cart",
    title: "Counter Text",
    key: "cart-counter-text",
    global: false,
  },
  {
    group: "Cart",
    title: "Amount",
    key: "cart-amount",
    global: false,
  },
  {
    group: "Cart",
    title: "Border",
    key: "cart-border",
    global: false,
  },
  {
    group: "Cart",
    title: "Card Delete Icon",
    key: "cart-card-delete-icon",
    global: false,
  },
  {
    group: "Cart",
    title: "Card Delete Icon Hover",
    key: "cart-card-delete-icon-hover",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Background",
    key: "cart-mobile-background",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Title",
    key: "cart-mobile-title",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Card Text",
    key: "cart-mobile-card-text",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Icon",
    key: "cart-mobile-icon",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Counter Background",
    key: "cart-mobile-counter-background",
    global: false,
  },
  {
    group: "Cart",
    title: "Mobile Counter Text",
    key: "cart-mobile-counter-text",
    global: false,
  },
];
export const QUESTION_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Question",
    title: "Product Info",
    key: "question-info",
    global: false,
  },
  {
    group: "Question",
    title: "Title",
    key: "question-title",
    global: false,
  },
  {
    group: "Question",
    title: "Description",
    key: "question-description",
    global: false,
  },
  {
    group: "Question",
    title: "List Item",
    key: "question-list-item",
    global: false,
  },
  {
    group: "Question",
    title: "Selected List Item",
    key: "question-list-selected-item",
    global: false,
  },
  {
    group: "Question",
    title: "Card Background",
    key: "question-card-background",
    global: false,
  },
  {
    group: "Quiz",
    title: "Info Card Background",
    key: "quiz-info-background",
    global: false,
  },
  {
    group: "Quiz",
    title: "Info Card Icon",
    key: "quiz-info-icon",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "Quiz",
    title: "Info Card Text",
    key: "quiz-info-text",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "Label Background",
    title: "Popular Label Background",
    key: "question-popular-label-background",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "Label Text",
    title: "Popular Label Text Color",
    key: "question-popular-label-color",
    fallbackKey: "text",
    global: false,
  },
];
export const PROFESSION_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Icon",
    title: "Right Arrow",
    key: "profession-right-arrow",
    global: false,
  },
  {
    group: "Profession",
    title: "Title",
    key: "profession-title",
    global: false,
  },
  {
    group: "Profession",
    title: "Description",
    key: "profession-description",
    global: false,
  },
  {
    group: "Profession",
    title: "Background Card",
    key: "profession-background-card",
    global: false,
  },
  {
    group: "Profession",
    title: "Item",
    key: "profession-item",
    global: false,
  },
  {
    group: "Profession",
    title: "Selected Item",
    key: "profession-selected-item",
    global: false,
  },
];
export const MODAL_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Modal",
    title: "Background",
    key: "modal-background",
    global: false,
  },
];
export const RESULT_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Result",
    title: "Confetti",
    key: "confetti-color",
    global: false,
  },
  {
    group: "Result",
    title: "Card Background",
    key: "product-card-background",
    global: true,
  },
  {
    group: "Result",
    title: "Title",
    key: "product-title",
    global: true,
  },
  {
    group: "Result",
    title: "Description",
    key: "product-desc",
    global: true,
  },
  {
    group: "Result Card Tag",
    title: "Text",
    key: "product-tag-text",
    global: true,
  },
  {
    group: "Result Card Tag",
    title: "Positive Background",
    key: "product-tag-positive",
    global: true,
  },
];
export const POLICY_CARD_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Policy",
    title: "Card Background",
    key: "policy-card-background",
    fallbackKey: "product-card-background",
    global: false,
  },
  {
    group: "Policy",
    title: "Title",
    key: "policy-title",
    fallbackKey: "product-title",
    global: false,
  },
  {
    group: "Policy",
    title: "Description",
    key: "policy-desc",
    fallbackKey: "product-desc",
    global: false,
  },
  {
    group: "Policy",
    title: "Price",
    key: "policy-price",
    fallbackKey: "product-price",
    global: false,
  },
  {
    group: "Policy Tag",
    title: "Text",
    key: "policy-tag-text",
    fallbackKey: "product-tag-text",
    global: false,
  },
  {
    group: "Policy Tag",
    title: "Positive Background",
    key: "policy-tag-positive",
    fallbackKey: "product-tag-positive",
    global: false,
  },
  {
    group: "Policy Tag",
    title: "Positive Background (Policy End)",
    key: "policy-tag-end-positive",
    fallbackKey: "product-tag-end-positive",
    global: false,
  },
  {
    group: "Policy",
    title: "List Check Icon",
    key: "policy-list-check-icon",
    fallbackKey: "product-list-check-icon",
    global: false,
  },
];
export const NAVIGATION_PANE_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Navigation Pane",
    title: "Menu icon",
    key: "nav-pane-icon-color",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item background",
    key: "nav-pane-item-bg",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item text",
    key: "nav-pane-item-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item active",
    key: "nav-pane-item-active-bg",
    fallbackKey: "input-label-background",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item active text",
    key: "nav-pane-item-active-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item hover background",
    key: "nav-pane-item-hover-bg",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item hover text",
    key: "nav-pane-item-hover-text",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item border",
    key: "nav-pane-item-border",
    fallbackKey: "text",
    global: true,
  },
  {
    group: "Navigation Pane",
    title: "Menu item counter background",
    key: "nav-pane-count-bg",
    fallbackKey: "background-illustration",
    global: true,
  },
];
export const PDP_DOCUMENT_DOWNLOAD: EditorColorGroupProps[] = [
  {
    group: "PDP download",
    title: "Card Background",
    key: "pdp-document-title",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "PDP download",
    title: "Card Background",
    key: "pdp-document-card-background",
    global: false,
  },
  {
    group: "PDP download",
    title: "Card Background",
    key: "pdp-document-card-background",
    global: false,
  },
  {
    group: "PDP download",
    title: "Active Card Background",
    key: "pdp-document-active-card-background",
    global: false,
  },
  {
    group: "PDP download",
    title: "Old Card Background",
    key: "pdp-document-old-card-background",
    global: false,
  },
  {
    group: "PDP download",
    title: "Card text",
    key: "pdp-document-card-text",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "PDP download",
    title: "Card icon",
    key: "pdp-document-card-icon",
    global: false,
  },
];

export const PDP_INFO: EditorColorGroupProps[] = [
  {
    group: "PDP info",
    title: "Card Background",
    key: "pdp-info-title",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "PDP info",
    title: "Card Background",
    key: "pdp-info-card-background",
    fallbackKey: "product-card-background",
    global: false,
  },
  {
    group: "PDP info",
    title: "Card text",
    key: "pdp-info-card-text",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "PDP info",
    title: "Card description",
    key: "pdp-info-card-description",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "PDP info",
    title: "Card icon",
    key: "pdp-info-card-icon",
    global: false,
  },
];
export const OWN_INVOICE_COLOR_FIELDS: EditorColorGroupProps[] = [
  {
    group: "Invoice",
    title: "Card Background",
    key: "invoice-card-background",
    global: false,
  },
  {
    group: "Invoice",
    title: "Card Text",
    key: "invoice-card-text",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "Invoice Tag",
    title: "Text",
    key: "invoice-card-tag-text",
    fallbackKey: "text",
    global: false,
  },
  {
    group: "Invoice Tag",
    title: "Success Background",
    key: "invoice-card-tag-success-background",
    global: false,
  },
  {
    group: "Invoice Tag",
    title: "Warning Background",
    key: "invoice-card-tag-warning-background",
    global: false,
  },
  {
    group: "Invoice Tag",
    title: "Error Background",
    key: "invoice-card-tag-error-background",
    global: false,
  },
  {
    group: "Invoice Tag",
    title: "Unknown Background",
    key: "invoice-card-tag-unknown-background",
    global: false,
  },
];
