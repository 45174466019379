import deepmerge from "deepmerge";

const overwriteMerge = (
  destinationArray: any[],
  sourceArray: any[],
  options: any
) => sourceArray;

export const intlMerge = (defaultTranslation: any, translation: any) => {
  // arraymerge =>  https://github.com/TehShrike/deepmerge#arraymerge
  return deepmerge.all([defaultTranslation, translation], {
    arrayMerge: overwriteMerge,
  });
};
