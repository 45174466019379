import getSymbolFromCurrency from "currency-symbol-map";

export const currencyFormatter = (
  num: string,
  currency: string,
  isDecimalsHidden?: boolean
) => {
  let formatter;
  switch (currency) {
    case "EUR":
      formatter = new Intl.NumberFormat("nl-Nl", {
        minimumFractionDigits: isDecimalsHidden ? 0 : 2,
        minimumIntegerDigits: 1,
      });
      break;
    case "USD":
    default:
      formatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: isDecimalsHidden ? 0 : 2,
        minimumIntegerDigits: 1,
      });
      break;
  }

  return `${getSymbolFromCurrency(currency)} ${formatter.format(Number(num))}`;
};
