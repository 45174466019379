import React, { FC } from "react";
import {
  Flex,
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ColorEditorForm from "../color-editor-form";
import { EditorPopoverProps } from "../types";
import { rem } from "polished";
import { useColors } from "hooks/useColors";
import { useTranslation } from "react-i18next";
import { usePartnerStore } from "store/partner";

const EditorPopover: FC<EditorPopoverProps> = ({
  trigger,
  colors,
  isGlobal,
}) => {
  const { getColor } = useColors();
  const { t } = useTranslation();
  const { brand } = usePartnerStore();
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="auto"
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      {isOpen && (
        <PopoverContent
          p={5}
          border="white"
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          zIndex={200}
        >
          <FocusLock persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <Flex direction="row" gap={4}>
              <Text
                fontSize={rem(18)}
                lineHeight={rem(24)}
                fontWeight="bold"
                fontFamily={brand?.font_primary}
                color={getColor("title")}
              >
                {t("adminEditor.popup.title")}
              </Text>
              {isGlobal && (
                <Tag size="sm" variant="solid" colorScheme="teal">
                  Global change
                </Tag>
              )}
            </Flex>
            <ColorEditorForm
              {...{
                onClose,
                colors,
              }}
            />
          </FocusLock>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default EditorPopover;
