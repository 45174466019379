import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
  ExtraCoverageType,
  FormattedQuestionsType,
  QuestionType,
} from "../types/types";
import { QUESTIONS_STORE } from "../constants/constant";
import {
  getPresetPriceForAovProduct,
  getQuestionsForProducts,
} from "services/api/questions";
import { useProductsStore } from "./products";
import { chain, isEmpty } from "lodash";

const initialQuestionsState = {
  questions: undefined,
  sortedRoutes: undefined,
  extraCoverages: undefined,
  nextRoute: "",
  animate: "",
  currentQuestionIndex: 0,
  latestQuestionIndex: 0,
  currentQuestion: undefined,
  currentProduct: undefined,
  hidePrevious: false,
  formalities: [],
  presets: undefined,
};
export const useQuestionsStore = create(
  devtools(
    persist<FormattedQuestionsType>(
      (set, get) => ({
        ...initialQuestionsState,
        getQuestions: async (
          productIdList: string[],
          isCalculator?: boolean
        ) => {
          try {
            const productQuestions = await getQuestionsForProducts(
              productIdList
            );
            const questions: QuestionType[] = productQuestions.flat(1);
            const products = useProductsStore.getState().products;
            // get extra coverages listed under product names
            const extraCoverages: ExtraCoverageType = chain(questions)
              .filter((question) => question.group === "extra_coverage")
              .map((question) => {
                const product = products.find(
                  (product) => product.id === question.product
                );
                return {
                  ...question,
                  product_name: product?.name,
                  price_currency: product?.price_currency,
                };
              })
              .groupBy((question) => question.product_name)
              .value();
            set(() => ({
              questions: questions.filter((question) =>
                isCalculator
                  ? question.group === "price" ||
                    (question.group === "normal" &&
                      question.options.find(
                        (option) => option.consequence === "price_increase"
                      ))
                  : question.group !== "extra_coverage"
              ),
              extraCoverages: isEmpty(extraCoverages)
                ? undefined
                : extraCoverages,
              currentQuestionIndex: 0,
              latestQuestionIndex: 0,
            }));
          } catch (err: unknown) {
            console.error(err);
            throw err;
          }
        },
        setNextRoute: (route) => {
          set(() => ({
            nextRoute: route,
          }));
        },
        setAnimate: (animation) => {
          set(() => ({
            animate: animation,
          }));
        },
        setSortedRoutes: (routes) => {
          set(() => ({
            sortedRoutes: routes,
          }));
        },
        setCurrentQuestionIndex: (index: number, latestIndex: number) => {
          set(() => ({
            currentQuestionIndex: index,
          }));
          if (index > latestIndex) {
            set(() => ({
              latestQuestionIndex: index,
            }));
          }
        },
        formalitiesChecked: (item) => {
          const getFormalities = get().formalities;
          if (getFormalities.includes(item)) {
            set(() => ({
              formalities: getFormalities.filter((i) => i !== item),
            }));
          } else {
            set(() => ({
              formalities: [...getFormalities, item],
            }));
          }
        },
        setHidePrevious: (value: boolean) => {
          set(() => ({
            hidePrevious: value,
          }));
        },
        setAovPresetPrices: async (productId: string) => {
          try {
            const presets = await getPresetPriceForAovProduct(productId);
            set(() => ({
              presets,
            }));
          } catch (err: unknown) {
            console.error(err);
            throw err;
          }
        },
        clearQuestions: () => {
          set(() => initialQuestionsState);
        },
      }),
      {
        name: QUESTIONS_STORE,
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
