import { Flex } from "@chakra-ui/react";
import { media } from "@utils/media";
import { rem } from "polished";
import { FC } from "react";
import ContentLoader from "react-content-loader";

const TitleLoader: FC = () => {
  return (
    <Flex
      sx={{
        flexDirection: "row",
        gap: 10,
        [media.large]: {
          flexDirection: "column",
          gap: 0,
        },
      }}
    >
      <Flex
        sx={{
          width: "100%",
        }}
      >
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#e0e0e0"
          viewBox="0 0 400 90"
        >
          <rect x="0" y="0" rx="0" ry="5" width="100%" height="50%" />
          <rect x="0" y="55" rx="0" ry="5" width="100%" height="20%" />
        </ContentLoader>
      </Flex>
      <Flex
        sx={{
          width: "100%",
          padding: rem(16),
          border: "1px solid #e0e0e0",
        }}
      >
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#e0e0e0"
          viewBox="0 0 400 60"
        >
          <rect x="0" y="0" rx="0" ry="5" width="100%" height="60%" />
          <rect x="0" y="75%" rx="0" ry="5" width="100%" height="30%" />
        </ContentLoader>
      </Flex>
    </Flex>
  );
};

export default TitleLoader;
