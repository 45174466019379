export const useTrengo = () => {
  let script: HTMLScriptElement, trengoWidget: HTMLElement | null;
  const initTrengo = (trengo_key: string) => {
    window.Trengo = window.Trengo || {};
    window.Trengo.key = trengo_key;
    window.Trengo.render = false;
    script = document.createElement("script");
    script.src = "https://static.widget.trengo.eu/embed.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    window.Trengo.on_close = () => {
      trengoWidget = document.getElementById("trengo-web-widget");
      setTimeout(() => {
        trengoWidget?.style.setProperty("display", "none");
        trengoWidget?.style.setProperty("visibility", "hidden");
      }, 1000);
    };
  };

  const openTrengo = (channel: "chat" | "help_center") => {
    trengoWidget = document.getElementById("trengo-web-widget");
    window.Trengo?.Api?.Widget?.render();
    window.Trengo.on_ready = () => {
      setTimeout(() => {
        window.Trengo?.Api?.Widget?.open(channel ?? "help_center");
      }, 1000);
    };
    window.Trengo.on_ready();
    trengoWidget?.style.setProperty("display", "block");
    trengoWidget?.style.setProperty("visibility", "visible");
  };

  return {
    initTrengo,
    openTrengo,
  };
};
