import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { StepsProgressBar } from "alicia-design-system";
import React, { useEffect, type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { rem } from "polished";
import { useLocation } from "react-router-dom";
import { media } from "@utils/media";
import { useLanguageTranslation } from "hooks/useLanguageTranslation";
import Cart from "features/shared/cart";
import { usePartnerStore } from "store/partner";
import { useStepStore } from "store/steps";
import { StepTypeEnum } from "src/types";
import {
  HeaderStepRouteEnum,
  GLOBAL_COLOR_FIELDS,
  NAVIGATION_PANE_COLOR_FIELDS,
} from "src/constants";
import { isInsuranceCalculatorRoute } from "@utils/getFlowPaths";
import { useColors } from "hooks/useColors";
import { useAuthStore } from "store/authentication";
import Editor from "features/editor";
import NavigationPane from "../plugins/navigation-pane";
import BannerTicker from "../plugins/banner-ticker";
import { PrivateHeader } from "../plugins/header";

// initial pages routes list
const INSURANCE_ROUTES = [
  "/profession",
  "/products/available-products",
  "/insurance-calculator/profession",
  "/insurance-calculator/available-products",
];

const PrivateLayout: FC<PrivateLayoutProps> = ({
  children,
  showCart = false,
  showHeaderNav = false,
  showSteps = true,
  showLeftNav = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getColor } = useColors();
  const { admin, clearAdminAuth } = useAuthStore();
  const { brand, enable_visual_effects, name } = usePartnerStore();
  const { selectedLanguage } = useLanguageTranslation();
  const { steps, setSteps, setCurrentStepId, currentId, isPageLoading } =
    useStepStore();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const [isInCheckoutFlow, setIsInCheckoutFlow] = useState(false);

  useEffect(() => {
    if (!isInCheckoutFlow) {
      setSteps(
        insuranceCalculatorRoute
          ? StepTypeEnum.CALCULATOR
          : StepTypeEnum.DEFAULT,
        t
      );
    }
  }, [selectedLanguage?.value, isInCheckoutFlow]);

  useEffect(() => {
    if (INSURANCE_ROUTES.includes(location.pathname)) {
      // include profession page available insurance page and the your insurances screens
      setCurrentStepId(HeaderStepRouteEnum.INSURANCE);
    }
    setIsInCheckoutFlow(location.pathname.includes("checkout"));
  }, [location]);

  return (
    <Flex
      flexDirection="column"
      h="100vh"
      w="100%"
      minHeight="calc(100vh)"
      background="#ffffff"
    >
      {admin && admin.name === name && (
        <Editor
          isGlobal
          isAdmin={admin && admin.name === name}
          triggerColor="#ffffff"
          position={{ top: 0, left: 0 }}
          colorsFields={GLOBAL_COLOR_FIELDS}
          component={
            <Flex
              height={16}
              bg="#C26D68"
              color="#ffffff"
              alignItems="center"
              justifyContent="center"
              gap={4}
              py={4}
              width="100%"
            >
              <Text
                fontSize={rem(16)}
                lineHeight={rem(22)}
                fontWeight="bold"
                fontFamily={brand?.font_primary}
              >
                {t("adminEditor.header.title")}
              </Text>
              <Button variant="primary" onClick={() => clearAdminAuth()}>
                {t("adminEditor.header.CTA")}
              </Button>
            </Flex>
          }
        />
      )}
      <BannerTicker />
      <Flex w="100%" zIndex="4" flexDirection="column" mb={rem(-8)}>
        <PrivateHeader {...{ showHeaderNav, isInCheckoutFlow }} />
      </Flex>
      <Flex
        justifyContent="space-between"
        w="100%"
        height="100%"
        overflow="hidden"
        sx={{
          flexDirection: "row",
          [media.desktop]: {
            flexDirection: "column-reverse",
            height: "fit-content",
          },
        }}
      >
        <Flex w="100%" flexDirection="column" height="100%" overflow="hidden">
          {!isPageLoading && showSteps && (
            <Flex zIndex={3}>
              <StepsProgressBar
                items={steps}
                currentItem={currentId}
                itemColor={getColor("steps-text", "text") ?? ""}
                highlightColor={
                  getColor("steps-label-background", "accent-positive-1") ?? ""
                }
                isAnimated
                enableVisualEffects={enable_visual_effects}
              />
            </Flex>
          )}
          <Flex
            sx={{
              overflow: "hidden",
              mt: showLeftNav ? rem(8) : undefined,
              borderTop: showSteps
                ? "none"
                : `${rem(1)} solid rgba(0, 0, 0, 0.2)`,
              [media.desktop]: {
                borderTop: "none",
              },
            }}
            h="100%"
            w="100%"
          >
            {showLeftNav && (
              <Flex
                h="100vh"
                w={rem(259)}
                px={rem(14)}
                borderRight={`${rem(1)} solid rgba(0, 0, 0, 0.2)`}
                sx={{
                  display: "flex",
                  [media.desktop]: {
                    display: "none",
                  },
                }}
              >
                <Editor
                  isAdmin={admin && admin.name === name}
                  colorsFields={NAVIGATION_PANE_COLOR_FIELDS}
                  component={<NavigationPane />}
                  position={{
                    top: -3,
                    left: -2,
                  }}
                  width="100%"
                />
              </Flex>
            )}
            <Box
              width="100%"
              marginTop={rem(32)}
              height="100%"
              position="relative"
              sx={{
                overflowY: "auto",
                px: showCart === true ? rem(24) : rem(65),
                [media.desktop]: {
                  px: rem(14),
                },
              }}
            >
              {children}
            </Box>
          </Flex>
        </Flex>
        {showCart && (
          <Flex position="relative" mt={rem(8)}>
            <Cart isFunnelStarted={isInCheckoutFlow} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default PrivateLayout;

interface PrivateLayoutProps {
  children?: React.ReactNode;
  showCart?: boolean;
  showHeaderNav?: boolean;
  showSteps?: boolean;
  showLeftNav?: boolean;
}
