import { Flex } from "@chakra-ui/react";
import { media } from "@utils/media";
import { rem } from "polished";
import { FC } from "react";
import ContentLoader from "react-content-loader";

const HeaderLoader: FC = () => {
  return (
    <Flex
      sx={{
        background: "#f7f7f7",
        width: "100%",
        maxHeight: rem(68),
      }}
    >
      <Flex
        sx={{
          display: "flex",
          width: "100%",
          [media.large]: {
            display: "none",
          },
        }}
      >
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#ddd9d9"
          foregroundColor="#e0e0e0"
        >
          <rect x="2%" y="15" rx="0" ry="5" width="15%" height="60%" />
          <rect x="40%" y="15" rx="0" ry="5" width="20%" height="60%" />
          <rect x="83%" y="15" rx="0" ry="5" width="15%" height="60%" />
        </ContentLoader>
      </Flex>
      <Flex
        sx={{
          display: "none",
          [media.large]: {
            display: "flex",
            width: "100%",
          },
        }}
      >
        <ContentLoader
          speed={2}
          width="100%"
          backgroundColor="#ddd9d9"
          foregroundColor="#e0e0e0"
        >
          <rect x="10" y="20" rx="0" ry="5" width="25%" height="50%" />
          <rect x="35%" y="20" rx="0" ry="5" width="30%" height="50%" />
          <rect x="75%" y="20" rx="0" ry="5" width="20%" height="50%" />
        </ContentLoader>
      </Flex>
    </Flex>
  );
};

export default HeaderLoader;
