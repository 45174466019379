import React, { FC } from "react";
import { Box, useMediaQuery } from "@chakra-ui/react";

export const ModalBackdropBG: FC<BackgroundProps> = ({ color = "blue" }) => {
  const [isLargerThan375] = useMediaQuery("(min-width: 865px)", {
    ssr: true,
    fallback: false,
  });
  return (
    <>
      {isLargerThan375 ? (
        <Box
          zIndex={-1}
          padding={0}
          pos="absolute"
          left="none"
          width="100%"
          height="100%"
          top="none"
          overflow="hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height="100%"
            width="100%"
            viewBox="0 0 542 407"
            preserveAspectRatio="none"
          >
            <path
              d="M0 10C0 4.47716 4.47715 0 10 0H531.605C537.28 0 541.817 4.71679 541.597 10.3873L526.611 397.066C526.398 402.562 521.787 406.854 516.291 406.674L9.67194 390.042C4.27972 389.865 0 385.443 0 380.048V10Z"
              fill={color}
              stroke={color}
            />
          </svg>
        </Box>
      ) : (
        <Box
          id="test-1"
          zIndex={-1}
          padding={0}
          pos="absolute"
          left="none"
          width="100%"
          height="100%"
          top="none"
          overflow="hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            height="100%"
            width="100%"
            viewBox="0 0 375 675"
            preserveAspectRatio="none"
          >
            <path
              d="M-83 10C-83 4.47717 -78.5228 0 -73 0H448.764C454.378 0 458.892 4.62084 458.761 10.2336L443.462 664.91C443.332 670.467 438.695 674.852 433.138 674.671L-73.3263 658.136C-78.7193 657.96 -83 653.537 -83 648.141V10Z"
              fill={color}
              stroke={color}
            />
          </svg>
        </Box>
      )}
    </>
  );
};

type BackgroundProps = {
  color?: string;
};
