import { TITLE_STORE } from "src/constants";
import { TitleType } from "src/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialStepsState = {
  title: "",
};

export const useTitleStore = create(
  devtools(
    persist<TitleType>(
      (set) => ({
        ...initialStepsState,
        setTitle: (title: string) => {
          set(() => ({
            title,
          }));
        },
      }),
      {
        name: TITLE_STORE,
      }
    )
  )
);
