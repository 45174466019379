import {
  useFeatureFlagEnabled,
  useFeatureFlagPayload,
  useFeatureFlagVariantKey,
} from "posthog-js/react";
import { getPartnerSlug } from "@utils";
import { getEnvironment } from "@utils/getEnvironment";

// Define the type structure for your feature flag data
type FeatureFlagData = {
  active: boolean;
  include: string[];
  exclude: string[];
};

type ABTestData = {
  active: boolean;
  isTestVariant: boolean;
};

type Environments = "development" | "qa" | "stage" | "production";

type FeatureFlag = Record<Environments, FeatureFlagData>;

// Type guard function to validate that the payload matches the FeatureFlag type
const isFeatureFlag = (data: any): data is FeatureFlag => {
  return (
    data &&
    ["development", "qa", "stage", "production"].every((env) => {
      const envData = data[env];
      return (
        envData &&
        typeof envData.active === "boolean" &&
        Array.isArray(envData.include) &&
        Array.isArray(envData.exclude)
      );
    })
  );
};

const checkEnvStatus = (flagIdentifier: string): boolean => {
  const domainData = getPartnerSlug(getEnvironment()); // Get partner from domain data
  const data: any = useFeatureFlagPayload(flagIdentifier); // Retrieve the payload

  // Validate that the data matches the expected FeatureFlag type
  if (!isFeatureFlag(data)) {
    return false; // If validation fails, return false
  }

  // Now that data is of type FeatureFlag, you can safely use it
  const envData: FeatureFlagData = data[domainData.environment];

  // Check if the feature flag is active for the environment
  if (!envData.active) {
    return false;
  }

  // Check if partner is included or excluded
  const { include, exclude } = envData;

  // Include check: if "*" is in include array or domainData.partner is explicitly included
  const isIncluded =
    include.includes("*") || include.includes(domainData.partner);

  // Exclude check: if domainData.partner is in exclude array
  const isExcluded = exclude.includes(domainData.partner);

  // Return true only if included and not excluded, otherwise false
  return isIncluded && !isExcluded ? true : false;
};

const checkAbTestStatus = (flagIdentifier: string): ABTestData => {
  const checkStatus = checkEnvStatus(flagIdentifier);
  const variant: any = useFeatureFlagVariantKey(flagIdentifier); // Retrieve the variant

  return {
    isTestVariant: variant === "test",
    active: checkStatus,
  };
};

export const featureFlags = {
  IsKnabPrefillDeeplinkEnabled: () => {
    const domainData = getPartnerSlug(getEnvironment());
    const isFeatureEnabled = useFeatureFlagEnabled("knab-prefill-deeplink");

    return domainData.partner !== "knab" &&
      domainData.environment !== "development"
      ? false
      : isFeatureEnabled;
  },
  IsEmailAddressOnTheStRTEnabled: () =>
    useFeatureFlagEnabled("tech-2669-show-email-on-the-start"),
  IsProfessionFeedbackEnabled: () =>
    useFeatureFlagPayload("tech-3325-hide-profession"),
  IsTickerEnabled: () => checkEnvStatus("tech-4596-usp-ticker"),
  // preset & review feature flags
  // A/B test flags
  IsAovPresetEnvEnabled: () => checkAbTestStatus("tech-4524-aov-presets"),
};
