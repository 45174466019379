import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { getEnvironment } from "@utils/getEnvironment";

const newRelicOptions = {
  init: {
    distributed_tracing: {
      enabled: true,
    },
    privacy: {
      cookies_enabled: true,
    },
    ajax: {
      deny_list: ["bam.eu01.nr-data.net"],
    },
  },
  info: {
    beacon: "bam.eu01.nr-data.net",
    errorBeacon: "bam.eu01.nr-data.net",
    licenseKey: "NRJS-c054ecdff63b652fa36",
    applicationID: "538559154",
    sa: 1,
  },
  loader_config: {
    accountID: "3480953",
    trustKey: "3480953",
    agentID: "538559154",
    licenseKey: "NRJS-c054ecdff63b652fa36",
    applicationID: "538559154",
  },
};

export const configureNewRelic = () => {
  const environment = getEnvironment();
  if (environment === "production") {
    new BrowserAgent(newRelicOptions);
  }
};
