import React, { FC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { BackgroundProps } from ".";

export const InternalServerErrorBG: FC<BackgroundProps> = ({
  color = "blue",
  left = "none",
  right = "none",
  top = "none",
  bottom = "none",
  mobile = false,
  transition = false,
}) => {
  return (
    <>
      {mobile ? (
        <Flex alignItems="center" justifyContent="center" w="inherit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="287"
            height="113"
            viewBox="0 0 287 113"
            fill="none"
          >
            <motion.path
              d="M100.416 22.3602C113.354 5.83049 173.554 -5.74779 175.226 50.372C176.899 106.492 120.232 116.545 105.121 91.2927C89.459 65.1197 106.842 13.6074 131.733 15.5022"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M203.796 22.3602C216.735 5.83049 276.934 -5.74779 278.607 50.372C280.279 106.492 223.612 116.545 208.501 91.2927C192.839 65.1197 210.222 13.6074 235.114 15.5022"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M66.6964 10.998H14.5061L10.1708 45.157H23.9449C73.0326 45.157 73.5328 103.04 29.4425 103.04C20.3714 103.04 1 100.701 1 100.701"
              strokeWidth="16"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
          </svg>
        </Flex>
      ) : (
        <Box
          zIndex={-1}
          pos="absolute"
          w="-webkit-fill-available"
          h="-webkit-fill-available"
          top={top}
          left={left}
          right={right}
          bottom={bottom}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
          >
            <motion.path
              d="M301.112 65.0731C339.911 15.5065 520.428 -19.2126 525.443 149.071C530.458 317.354 360.534 347.5 315.222 271.777C268.257 193.294 320.383 38.8266 395.023 44.5086"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M611.112 65.0731C649.911 15.5065 830.428 -19.2126 835.443 149.071C840.458 317.354 670.534 347.5 625.222 271.777C578.257 193.294 630.383 38.8266 705.023 44.5086"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.path
              d="M200 31H43.5L30.5 133.43H71.8034C219 133.43 220.5 307 88.2887 307C61.0878 307 3 299.987 3 299.987"
              strokeWidth="48"
              stroke={color}
              strokeLinecap="square"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{
                duration: transition ? 1 : 0,
                yoyo: Infinity,
                ease: "easeInOut",
              }}
            />
          </svg>
        </Box>
      )}
    </>
  );
};
