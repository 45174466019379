import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { instance } from "../services/api/helpers/axios";
import { AvailableLanguageType, LanguageType } from "../types";
import { useLanguageStore, usePartnerStore } from "../store";
import { useToast } from "@chakra-ui/react";

const useLanguageTranslation = () => {
  const toast = useToast();
  const { i18n, t } = useTranslation();
  const { available_languages, default_language } = usePartnerStore();
  const { language, setLanguage } = useLanguageStore();
  const [languages, setLanguagesType] = useState<LanguageType[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<
    LanguageType | undefined
  >();

  useEffect(() => {
    setLanguagesType(
      available_languages?.map((item: AvailableLanguageType) => {
        return {
          label: item.name,
          labelSm: item.abbreviation,
          value: item.code,
        };
      })
    );
  }, [available_languages]);

  useEffect(() => {
    const changeLanguage = async () => {
      try {
        await i18n.changeLanguage(
          language !== ""
            ? language
            : default_language
            ? default_language.code
            : ""
        );
      } catch (error) {
        toast({
          title: String(error),
          variant: "solid",
          isClosable: true,
          status: "warning",
        });
      }
    };
    changeLanguage();
  }, []);

  useEffect(() => {
    if (i18n && i18n.language && languages) {
      setSelectedLanguage(
        languages.find(
          (lang: LanguageType) =>
            lang.value === i18n.language.toLocaleLowerCase()
        )
      );
    }
  }, [i18n?.language, languages]);

  const changeSelectedLanguage = async (languageCode: string) => {
    try {
      instance.defaults.headers["Accept-Language"] = String(languageCode);
      setSelectedLanguage(
        languages.find((lang: LanguageType) => lang.value === languageCode)
      );
      setLanguage(languageCode);
      await i18n.changeLanguage(languageCode);
    } catch (error) {
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    }
  };

  return {
    i18n,
    t,
    selectedLanguage,
    languages,
    changeSelectedLanguage,
  };
};

export { useLanguageTranslation };
