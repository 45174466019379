import { getEnvironment } from "@utils/getEnvironment";
import { createApiEnvUrl } from "../../../utils/createApiEnvUrl";

export const getApiUrl = (basePath?: "/api", isAdmin?: boolean): string => {
  switch (getEnvironment()) {
    case "production":
    case "stage":
    case "qa":
      return createApiEnvUrl(getEnvironment(), basePath, undefined, isAdmin);
    default:
      return createApiEnvUrl(undefined, basePath, undefined, isAdmin);
  }
};
