import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { media } from "@utils/media";
import { BackgroundProps } from ".";

export const CircleBG: FC<BackgroundProps> = ({
  color = "blue",
  left = "none",
  right = "none",
  top = "none",
  bottom = "none",
}) => {
  return (
    <Box
      zIndex={-1}
      pos="absolute"
      w="full"
      h="full"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      sx={{
        display: "block",
        [media.desktop]: {
          display: "none",
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
      >
        <motion.path
          d="M774.174 656.515C566.321 731.542 264.59 765.781 139.667 594.903C-106.721 257.878 145.346 -48.7916 475.172 64.1573C734.141 152.841 719.306 347.515 619.69 432.132"
          fill="transparent"
          strokeWidth="80"
          stroke={color}
          strokeLinecap="square"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
