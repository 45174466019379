import { formatToastErrors } from "@utils/toast-errors";
import { adminInstance, instance } from "./helpers/axios";

export const getPartners = async () => {
  try {
    const { data } = await instance.get("/partners");
    return data.result[0];
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const adminUpdateColor = async (
  colors: { id: string; value: string }[]
) => {
  try {
    const { data } = await adminInstance.patch(
      `/color-key-pairs/update`,
      colors
    );
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};

export const adminCreateColor = async (colors: {
  key: string;
  value: string;
}) => {
  try {
    const { data } = await adminInstance.post(`/color-key-pairs`, colors);
    return data;
  } catch (err: any) {
    throw formatToastErrors(err);
  }
};
