import React, { FC, useEffect, useState } from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import EditorPopover from "./popover";
import { EditorProps, PickerInputData } from "./types";
import { usePartnerStore } from "store/partner";
import { COLORS } from "hooks/useColors";
import { random } from "lodash";

const Editor: FC<EditorProps> = ({
  isAdmin = false,
  component,
  colorsFields,
  position = { top: 0, right: 0 },
  triggerColor = "black",
  isGlobal = false,
  hide = false,
  width = undefined,
  style = undefined,
}) => {
  const [partnerColors, setPartnerColors] = useState<PickerInputData[]>([]);
  const { colors } = usePartnerStore();

  const getColors: PickerInputData[] = colorsFields.map((field) => {
    const color = colors?.find((c) => c.key === field.key);
    const fallbackColor = colors?.find((c) => c.key === field.fallbackKey);
    return {
      id: color?.id ?? `new-${random(111111, 999999)}`,
      value:
        color?.value ??
        fallbackColor?.value ??
        COLORS[field?.key as keyof typeof COLORS] ??
        "#ffffff",
      key: field.key,
      group: field.group,
      title: field.title,
      global: field.global,
    };
  });

  useEffect(() => {
    setPartnerColors(getColors);
  }, []);

  useEffect(() => {
    setPartnerColors(getColors);
  }, [colors]);

  if (hide) return component;

  if (
    !isAdmin ||
    !colors?.length ||
    !colorsFields.length ||
    !getColors.length ||
    !partnerColors.length
  )
    return component;
  return (
    <Flex position="relative" {...{ width, style }}>
      <Box position="absolute" {...position} padding={4}>
        <EditorPopover
          isGlobal={isGlobal}
          trigger={
            <IconButton
              aria-label="editor-icon"
              size="sm"
              icon={<EditIcon />}
              zIndex={1}
              color={triggerColor}
            />
          }
          colors={partnerColors}
        />
      </Box>
      {component}
    </Flex>
  );
};

export default Editor;
