import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { BackgroundProps } from ".";
import { media } from "@utils/media";

export const CircleTopBG: FC<BackgroundProps> = ({
  color = "blue",
  left = "none",
  right = "none",
  top = "none",
  bottom = "none",
}) => {
  return (
    <Box
      zIndex={-1}
      pos="absolute"
      display="flex"
      w="full"
      h="full"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      sx={{
        display: "block",
        [media.large]: {
          display: "none",
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
      >
        <motion.path
          d="M796.67 41.5644C871.463 82.5848 995.257 363.059 796.671 547.476C449.176 870.179 -60.5596 583.035 37.2443 230.256C103.961 -10.3933 491.917 -31.2265 662.505 119.827"
          strokeWidth="48"
          stroke={color}
          strokeLinecap="square"
          animate={{ pathLength: 1 }}
          transition={{
            duration: 1,
            yoyo: Infinity,
            ease: "easeInOut",
          }}
        />
      </svg>
    </Box>
  );
};
